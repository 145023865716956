<template>
    <div v-if="info && Object.keys(info).length">
        <div class="current">
            <div
                class="text click-op"
                :class="{ select: breadArr.length >= 1 }"
                style="margin-left:0;"
                @click="getCategory(null)"
            >
                {{ "全部设备" | language
                }}<span v-if="breadArr.length"> ></span>
            </div>

            <div
                class=" text "
                v-for="(item, index) in breadArr"
                :key="item._id"
                @click="getCategory(item)"
            >
                {{ item.name | language }}
                <span v-if="breadArr.length - 1 !== index"> ></span>
            </div>
        </div>
        <!-- <div class="bread-header">
            <Breadcrumb separator=">">
                <BreadcrumbItem @click="getCategory(null)"><span class="_content">{{"全部设备"|language}}</span></BreadcrumbItem>
                <BreadcrumbItem to="/components/breadcrumb">Components</BreadcrumbItem>
                <BreadcrumbItem>Breadcrumb</BreadcrumbItem>
            </Breadcrumb>
        </div>
        </Breadcrumb> -->
        <div class="container">
            <div class="top-area">
                <div class="img-container">
                    <img alt="" class="img img-fit" :src="info.image.src" />
                </div>
                <div class="content-area">
                    <div class="title">{{ info.name }}</div>
                    <div class="content">
                        <div class="text">
                            {{ "类别" | language }}：{{ info.categoryName }}
                        </div>
                        <div class="text">
                            {{ "编号" | language }}：{{ info.deviceNo }}
                        </div>
                        <div class="text">
                            {{ "规格" | language }}：{{ info.spec }}
                        </div>
                        <div class="text">
                            {{ "品牌" | language }}：{{ info.band }}
                        </div>
                        <div class="text">
                            {{ "价值" | language }}：{{ info.price }}
                        </div>
                        <div class="text">
                            {{ "管理员" | language }}：{{ info.adminName }}
                        </div>
                        <div class="text">
                            {{ "地址" | language }}：{{ info.address }}
                        </div>
                    </div>
                    <div class="stock-container">
                        <div class="stock">
                            {{ "库存" | language
                            }}<span class="num">{{ info.stock }}</span
                            >{{ "套" | language }}
                        </div>
                        <div class="sub-chunk">
                            <div class="add-num">
                                {{ "预约数量" | language }}
                            </div>
                            <ButtonGroup>
                                <Button
                                    type="primary"
                                    @click="remove"
                                    :disabled="disableR || !disableAll"
                                >
                                    <Icon type="md-remove" />
                                </Button>
                                <Button
                                    type="primary"
                                    ghost
                                    :disabled="!disableAll"
                                >
                                    {{ num }}
                                </Button>
                                <Button
                                    :disabled="disableS || !disableAll"
                                    type="primary"
                                    @click="add"
                                >
                                    <Icon type="md-add" />
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                    <div
                        class="reservation-btn click-op mr-t2"
                        :class="{ 'disabled-btn': !disableAll }"
                        @click="reservation"
                    >
                        {{ text | language }}
                    </div>
                    <div class="tip-text">
                        {{ tip | language }}
                        <!-- {{"归还日期为"|language}}<span class="day">30</span>{{"天 信用值高于"|language}}<span class="day">{{info.threhold||80}}</span>{{"分可借，请保持良好信用"|language}} -->
                    </div>
                </div>
                <Search @search="handleSearch"></Search>
            </div>
            <Tabs value="name1" class="tabs-container mr-t4 mr-b4">
                <TabPane :label="'设备介绍' | language" name="name1">
                    <div class="sub-area" v-html="info.introduction"></div>
                </TabPane>
                <TabPane :label="'操作说明' | language" name="name2">
                    <div class="sub-area" v-html="info.instruction"></div>
                </TabPane>
                <TabPane :label="'预约说明' | language" name="name3">
                    <div class="sub-area" v-html="info.reservation"></div>
                </TabPane>
            </Tabs>
        </div>
    </div>
</template>

<script>
import { globalBus } from "../../assets/util/event";
import Search from "../../compontents/service/device/Search.vue";
import { languageTranslation } from "../../assets/util/language";
export default {
    components: {
        Search
    },
    data() {
        return {
            id: "",
            info: null,
            num: 1,
            show: 1,
            breadArr: [],
            disableR: true,
            disableS: false,
            text: "立即预约",
            disableAll: true,
            tip: "归还日期为30 天 信用值高于80分可借，请保持良好信用"
        };
    },
    mounted() {
        this.id = this.$route.params.id;
        this.fetchData();
    },
    computed: {
        select() {
            return JSON.parse(localStorage.getItem("set_device_type"));
        }
    },
    methods: {
        add() {
            if (this.num == this.info.stock) {
            } else {
                this.num += 1;
            }
        },
        remove() {
            if (this.num == 1) {
            } else {
                this.num -= 1;
            }
        },
        handleSearch(val) {
            this.$router.push(`/base/service/device?keyword=${val}`);
        },
        //点击导航纸屑
        getCategory(item) {
            if (!item) {
                return this.$router.push("/base/service/device");
            } else {
                return this.$router.push(
                    `/base/service/device?categoryId=${item._id}`
                );
            }
        },
        async fetchData() {
            let res = await this.$http.get(`/device/detail/${this.id}`);

            this.info = res;
            this.btnText(res);
            this.fetchBread();
        },
        //获取面包屑
        async fetchBread() {
            let res = await this.$http.get(
                `/device/category/ancestor?categoryId=${this.info.categoryId._id}`
            );
            this.breadArr = res;
        },
        btnText(item) {
            if (item.stock > 0) {
                this.disableAll = true;
                this.text = "立即预约";
            } else {
                this.disableAll = false;
                this.text = "暂无库存";
            }
        },
        async reservation() {
            if (!this.admin) {
                await this.$Modal.confirm({
                    title: languageTranslation("登录后才能进行此操作"),
                    content: languageTranslation("是否立即跳转到登录页面？"),
                    okText: languageTranslation("确定"),
                    cancelText: languageTranslation("取消"),
                    onOk: () => {
                        this.$router.push("/common/login");
                    }
                });
                return;
            }
            if (!this.info || !this.disableAll) return;
            let res = await this.$http.post(
                `/device/reserve/${this.info._id}`,
                {
                    amount: this.num
                }
            );
            if (res) {
                this.$Message.success(languageTranslation("预约成功"));
                this.fetchData();
            }
        }
    },
    watch: {
        num(val) {
            if (val <= 1) {
                this.disableR = true;
                this.disableS = false;
            } else if (val >= this.info.stock) {
                this.disableR = false;
                this.disableS = true;
            } else {
                this.disableR = false;
                this.disableS = false;
            }
        },
        $route(val) {
            this.id = val.params.id;
            this.fetchData();
        }
    }
};
</script>

<style lang="less" scoped>
.current {
    width: 1022px;
    display: flex;
    font-size: 14px;

    height: 20px;
    line-height: 20px;
    user-select: none;
    .current-title {
        color: #0091ff;
    }
    .text {
        margin-left: 10px;
        &:hover {
            cursor: pointer;
        }
    }
    .select {
        color: rgba(0, 0, 0, 0.5);
    }
}
.header-header {
    ._content {
        text-align: left;
    }
}
.container {
    width: 1022px;
    margin-top: 40px;
    // display: flex;
    // align-items: flex-start;
    // justify-content: space-between;
    // .left {
    //   width: 808px;
    .top-area {
        display: flex;
        align-items: flex-start;
        .img-container {
            width: 262px;
            height: 262px;
            .img {
                width: 262px;
                height: 262px;
                object-fit: cover;
            }
        }

        .content-area {
            margin-left: 60px;
            text-align: left;
            .title {
                margin-bottom: 10px;
                height: 30px;
                font-size: 22px;
                font-weight: 600;
                color: rgba(0, 0, 0, 0.85);
                line-height: 30px;
            }
            .content {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                opacity: 0.6;
                .text {
                    width: 200px;
                    height: 20px;
                    font-size: 14px;
                    margin-bottom: 5px;
                    font-weight: 500;

                    line-height: 20px;
                }
            }
            .stock-container {
                display: flex;
                align-items: center;
                .stock {
                    width: 130px;
                    text-align: left;
                    height: 29px;
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.6);
                    line-height: 29px;
                    .num {
                        color: #fa6400;
                        margin-left: 5px;
                        margin-right: 5px;
                    }
                }
                .sub-chunk {
                    display: flex;
                    align-items: center;
                    .add-num {
                        width: 64px;
                        height: 29px;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.6);
                        line-height: 29px;
                        margin-right: 10px;
                    }
                }
            }
            .reservation-btn {
                width: 120px;
                height: 30px;
                background: #3b95fc;
                border-radius: 2px;
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                color: #fff;
                line-height: 30px;
                border: 1px solid #3b95fc;
            }
            .disabled-btn {
                background: #f7f7f7;
                color: #c5c8ce;
                border-color: #dcdee2;
                &:hover {
                    cursor: not-allowed;
                }
            }
            .tip-text {
                margin-top: 10px;
                opacity: 0.6;
                font-size: 14px;
                line-height: 26px;
                .day {
                    font-size: 16px;
                    color: #fa6400;
                    line-height: 29px;
                }
            }
        }
    }
    .tabs-container {
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.12);
        .sub-area {
            width: 100%;
            min-height: 500px;
        }
    }

    // }
}
@media screen and (max-width: 960px) {
    .current {
        width: 100%;
    }
    .search {
        display: none;
    }
    .container {
        width: 100%;
        margin-top: 30px;
        .top-area {
            flex-direction: column;
            align-items: center;
            .content-area {
                margin-left: 0;
                margin-top: 15px;
            }
            .reservation-btn {
                margin: 20px auto;
            }
        }
    }
}
</style>
